button,
a {
  border: none;
  display: inline;
  line-height: 1.15;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: none;
}

html,
body,
button,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: inherit;
  font-family: Arial, helvetica, sans-serif;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: Arial, helvetica, sans-serif;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 70%;
  font-weight: inherit;
  font-family: Arial, helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}
